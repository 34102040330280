import { TranslateFunction } from '@pancakeswap/localization'
import { SalesSectionProps } from '.'

export const swapSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Trade anything. No registration, no hassle.'),
  bodyText: t('Trade any token on CORE in seconds, just by connecting your wallet.'),
  reverse: false,
  primaryButton: {
    to: '/swap',
    text: t('Trade Now'),
    external: false,
  },
  secondaryButton: {
    to: 'https://lfgswap.gitbook.io/',
    text: t('Learn'),
    external: true,
  },
  images: {
    path: '/images/home/trade/',
    attributes: [
      { src: 'WETH2', alt: t('WETH token') },
      { src: 'BTC', alt: t('BTC token') },
      { src: 'LFG', alt: t('LFG token') },
    ],
  },
})

export const earnSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Earn passive income with crypto.'),
  bodyText: t('LFGSwap makes it easy to make your crypto work for you.'),
  reverse: true,
  primaryButton: {
    to: '/farms',
    text: t('Explore'),
    external: false,
  },
  secondaryButton: {
    to: 'https://lfgswap.gitbook.io/lfgswap/products/farm',
    text: t('Learn'),
    external: true,
  },
  images: {
    path: '/images/home/earn/',
    attributes: [
      { src: '1', alt: t('Pie chart') },
      { src: '2', alt: t('Stocks chart') },
      { src: '3', alt: t('Folder with cake token') },
    ],
  },
})

export const cakeSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('LFG makes our world go round.'),
  bodyText: t(
    'LFG token is at the heart of the LFGSwap ecosystem. Buy it, win it, farm it, spend it, stake it... heck, you can even vote with it!',
  ),
  reverse: false,
  primaryButton: {
    to: '/swap?outputCurrency=0xF7a0b80681eC935d6dd9f3Af9826E68B99897d6D&chainId=1116',
    text: t('Buy LFG'),
    external: false,
  },
  secondaryButton: {
    to: 'https://lfgswap.gitbook.io/lfgswap/tokenomics/lfgswap-tokenomics',
    text: t('Learn'),
    external: true,
  },

  images: {
    path: '/images/home/cake/',
    attributes: [
      { src: '1', alt: t('Small 3d pancake') },
      { src: '2', alt: t('Small 3d pancake') },
      // { src: 'coin', alt: t('LFG token') },
      // { src: 'top-left', alt: t('Small 3d pancake') },
    ],
  },
})
